<template>
  <!--begin::Card-->
  <div class="card card-custom" :dir="$t('clan')=='ar'?'rtl':'ltr'">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          {{$t('TarrifInformation')}}
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >{{$t('ChangeTarrifInformation')}}</span
        >
      </div>
      <div class="card-toolbar">
        <button
          type="reset"
          class="btn btn-success mr-2"
          @click="save()"
          ref="kt_save_changes"
        >
          {{$t('Save Changes')}}
        </button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <div class="card-body">
        <!--begin::Heading-->
        <!--begin::Form Group-->
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">{{$t('TarrifName')}}</label>
          <div class="col-lg-9 col-xl-6">
            <input
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model="queue.name"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">{{$t('TarrifValue')}}</label>
          <div class="col-lg-9 col-xl-6">
            <input
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model="queue.name"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">{{$t('TarrifMode')}} ( {{toggleValue}} )</label>
          <div class="col-lg-9 col-xl-6">
            <span class="switch switch-icon switch-sm" style="float:right" >
      
                         
                <label>
                    
                  <input
                    type="checkbox"
                    name="select"
                    v-model="toggled"
                    @click="setToggle"
                    
                    
                  />
                  <span></span>
                </label>
              </span>
          </div>
        </div>
       
       
      </div>
    </form>
    <!--end::Form-->
  </div>
  <!--end::Card-->
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "QueueInformation",

  props: {
    queue: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      createData: [],
      toggled:false,
      toggleValue:'Fixed',
    };
  },
  async mounted() {
    this.createData = await this.$store.dispatch("queues/fetchCreateData");
  },
  methods: {
      setToggle(){
          if(this.toggled === true){
        this.toggled = false;
        this.toggleValue='Fixed'
        
        

      }
      else if(this.toggled === false){
        this.toggled = true
        this.toggleValue='Percentage'
       
        
      }
      },
    updateClients(value) {
      this.queue.clients = value;
    },
    updateDrivers(value) {
      this.queue.drivers = value;
    },
    save() {
      // set spinner to submit button
      const submitButton = this.$refs["kt_save_changes"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      // dummy delay
      setTimeout(() => {
        // send update request
        this.$store.dispatch("queues/update", this.queue);

        Swal.fire({
          title: "",
          text:this.$t('QeueAlert'),
          icon: "success",
          confirmButtonClass: "btn btn-secondary",
        });

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 2000);
    },
    cancel() {},
  },
};
</script>
